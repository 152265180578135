<template>
    <div>
        <div class="title">
            <div class="left">
                <el-row>
                    <el-col :span="2"><div class="grid-content bg-purple">乐器筛选</div></el-col>
                    <el-col :span="12">
                        <div class="grid-content bg-purple-light">
                            <el-checkbox-group v-model="filter.musicalIds">
                                <el-checkbox
                                    border=""
                                    style="margin: 0 15px 0 0"
                                    @change="onSearch"
                                    v-for="item in musicalList"
                                    :key="item.id"
                                    :label="item.id"
                                >
                                    {{ item.label }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="2">
                        <div class="grid-content bg-purple">类别筛选</div>
                    </el-col>
                    <el-col :span="12">
                        <div class="grid-content bg-purple-light" style="display: flex">
                            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" border style="margin-right: 15px">
                                全选
                            </el-checkbox>

                            <el-checkbox-group v-model="filter.modeIds">
                                <el-checkbox style="margin: 0 15px 0 0" border v-for="item in modeList" :key="item.id" :label="item.id">
                                    {{ item.label }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </el-col>
                </el-row>
            </div>

            <div class="right">
                <el-button @click="addSetClamp" type="primary">创建合集</el-button>
                <el-button @click="addMusic" type="primary">添加曲目</el-button>
            </div>
        </div>
        <el-divider></el-divider>
        <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="name" label="曲目名" min-width="180"></el-table-column>
            <el-table-column prop="SetClamp" label="所属合集" min-width="180">
                <template slot-scope="scope">
                    <el-popover placement="top-start" width="200" trigger="hover" :content="JSON.stringify(scope.row.SetClamp)">
                        <el-tag slot="reference">
                            {{ (scope.row.SetClamp || []).map(item => item.title).join(',') }}
                        </el-tag>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column prop="author" label="作曲家" min-width="180"></el-table-column>
            <el-table-column prop="createdAt" label="上传时间" min-width="180">
                <template slot-scope="scope">
                    {{ new Date(scope.row.createdAt).toLocaleString() }}
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip="" prop="sourceUrl" label="midi文件" min-width="100">
                <template slot-scope="scope">
                    <a target="__blank" style="color: rgb(0 115 255)" :href="scope.row.sourceUrl">
                        {{ scope.row.sourceUrl }}
                    </a>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="180" fixed="right">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
                    <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="page-wrapper">
            <el-pagination
                background
                @size-change="onSearch"
                @current-change="fetchList"
                :current-page.sync="filter.pageNo"
                :page-size.sync="filter.pageSize"
                layout="sizes, total, prev, pager, next"
                :total="total"
            ></el-pagination>
        </div>
        <el-dialog title="乐曲" :visible.sync="dialogFormVisible">
            <el-form ref="form" :model="form" label-width="100px">
                <el-form-item label="曲目名称" prop="name" :rules="[{ required: true, message: '此项必填', trigger: 'blur' }]">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="作曲家" prop="author" :rules="[{ required: true, message: '此项必填', trigger: 'blur' }]">
                    <el-input v-model="form.author"></el-input>
                </el-form-item>
                <el-form-item label="乐器" prop="musicalList" :rules="[{ required: true, message: '此项必填', trigger: 'blur' }]">
                    <el-checkbox-group v-model="form.musicalList">
                        <el-checkbox v-for="item in musicalList" :key="item.id" :label="item.id">
                            {{ item.label }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="风格类别" prop="modeList" :rules="[{ required: true, message: '此项必填', trigger: 'blur' }]">
                    <el-checkbox-group v-model="form.modeList">
                        <el-checkbox v-for="item in modeList" :key="item.id" :label="item.id">
                            {{ item.label }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="midi 文件">
                    <el-upload class="upload-demo" drag action="/admin-api/upload" :on-success="handleUploadSuccess">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                            将文件拖到此处，或
                            <em>点击上传</em>
                        </div>
                        <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="文件 URL" prop="sourceUrl" :rules="[{ required: true, message: '此项必填', trigger: 'blur' }]">
                    <el-input v-model="form.sourceUrl"></el-input>
                </el-form-item>

                <el-form-item label="tag 标签">
                    <el-checkbox-group v-model="form.tagList">
                        <el-checkbox v-for="item in tagList" :key="item.id" :label="item.id">
                            {{ item.value }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>

                <el-form-item label="所属合集">
                    <el-checkbox-group v-model="form.setClampList">
                        <el-checkbox v-for="item in setClampList" :key="item.id" :label="item.id">
                            {{ item.title }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="合集" :visible.sync="dialogSetClampFormVisible">
            <el-form ref="formSetClamp" :model="formSetClamp" label-width="100px">
                <el-form-item label="合集名称" prop="title" :rules="[{ required: true, message: '此项必填', trigger: 'blur' }]">
                    <el-input v-model="formSetClamp.title"></el-input>
                </el-form-item>
                <el-form-item label="说明" prop="detail" :rules="[{ required: true, message: '此项必填', trigger: 'blur' }]">
                    <el-input :rows="5" type="textarea" v-model="formSetClamp.detail"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-upload class="upload-demo" drag action="/admin-api/upload" :on-success="handleSetClampUploadSuccess">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                            将文件拖到此处，或
                            <em>点击上传</em>
                        </div>
                        <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="图片" prop="img" :rules="[{ required: true, message: '此项必填', trigger: 'blur' }]">
                    <el-input v-model="formSetClamp.img"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogSetClampFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveSetClamp">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { confirmation } from '@/utils/decorator';
import F from './fetch';

export default {
    name: 'ResourceList',
    components: {},
    watch: {
        'filter.modeIds'(val) {
            this.checkAll = this.filter.modeIds.length === this.modeList.length;
            this.isIndeterminate = !this.checkAll;
            if (this.filter.modeIds.length === 0) {
                this.isIndeterminate = false;
            }
            this.onSearch();
        },
    },
    data() {
        return {
            isIndeterminate: true,
            checkAll: false,
            filter: {
                pageNo: 1,
                pageSize: 10,
                modeIds: [],
                musicalIds: [],
            },
            radio: '',
            musicalList: [],
            setClampList: [],
            modeList: [],
            tagList: [],

            tableData: [{}],
            total: 0,
            form: {
                sourceUrl: '',
                tagList: [],
                setClampList: [],
                modeList: [],
            },

            formSetClamp: {
                title: '',
                detail: '',
                img: '',
            },
            dialogFormVisible: false,
            dialogSetClampFormVisible: false,
        };
    },
    async created() {
        F.fetchAllMusical().then(data => {
            this.musicalList = data;
        });
        F.fetchAllTag().then(data => {
            this.tagList = data;
        });
        F.fetchAllMode().then(data => {
            this.modeList = data;
        });
        F.fetchAllSetClamp().then(data => {
            this.setClampList = data;
        });

        this.fetchList();
    },
    mounted() {
        console.log(`this`, this);
        window.TTT = this;
    },
    methods: {
        handleCheckAllChange() {
            if (this.checkAll) {
                this.filter.modeIds = this.modeList.map(item => item.id);
                this.isIndeterminate = false;
            } else {
                this.filter.modeIds = [];
                this.isIndeterminate = false;
            }
        },
        addMusic() {
            this.dialogFormVisible = true;
            this.form = {
                sourceUrl: '',
                musicalList: [],
                tagList: [],
                setClampList: [],
                modeList: [],
            };
        },

        addSetClamp() {
            this.dialogSetClampFormVisible = true;
            this.formSetClamp = {
                title: '',
                detail: '',
                img: '',
            };
        },
        save() {
            if (this.form.id) {
                this.$refs.form.validate().then(() => {
                    F.update(this.form).then(e => {
                        this.fetchList(this.filter);
                        this.dialogFormVisible = false;
                        this.$message.success('成功');
                    });
                });
            } else {
                this.$refs.form.validate().then(() => {
                    F.add(this.form).then(e => {
                        this.fetchList(this.filter);
                        this.dialogFormVisible = false;
                        this.$message.success('成功');
                    });
                });
            }
        },
        saveSetClamp() {
            this.$refs.formSetClamp.validate().then(() => {
                F.addSetClamp(this.formSetClamp).then(e => {
                    this.fetchList(this.filter);
                    this.dialogSetClampFormVisible = false;
                    this.$message.success('成功');
                });
            });
        },
        handleUploadSuccess(res, file) {
            this.form.sourceUrl = `/upload/${res.data}`;
        },
        handleSetClampUploadSuccess(res, file) {
            this.formSetClamp.img = `/upload/${res.data}`;
        },

        onSearch() {
            this.filter.pageNo = 1;
            this.fetchList(this.filter);
        },

        fetchList(filter) {
            F.fetchList(filter).then(data => {
                console.log(`data`, data);
                this.tableData = data.data.list;
                this.total = data.data.total;
            });
        },
        editAmount(record, type) {
            this.editForm = {
                amount: '',
                remark: '',
                id: record.id,
                type: type,
            };
            this.visible = true;
        },

        // @confirmation('确定添加机器预算单吗？')
        openApproveUrl(record) {},

        mappingArr(arr) {
            return arr.map(item => item.id || item.value);
        },

        handleEdit(record) {
            const val = JSON.parse(JSON.stringify(record));
            console.log(val, val);
            ['tagList', 'musicalList', 'modeList'].forEach(key => {
                val[key] = this.mappingArr(val[key]);
            });

            val.setClampList = val.SetClamp.map(item => item.id);
            this.form = val;
            this.dialogFormVisible = true;
        },
        @confirmation('删除？')
        handleDelete(record) {
            F.del(record.id).then(data => {
                this.fetchList(this.filter);
            });
        },
    },
};
</script>

<style scoped>
.title {
    display: flex;
}
.left {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: #fff;
    padding: 20px;
    flex: 1;
}
.right {
    width: 300px;
    padding: 2px 20px;
    display: flex;

    align-items: flex-start;
    justify-content: flex-end;
}
</style>
