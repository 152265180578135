// body: JSON.stringify({
//     uname: 'zhangsan',
//     pwd: '456'
// }),
// headers: {
//     'Content-Type': 'application/json'
// }

const obj = {
    fetchAllMusical() {
        return fetch(`/admin-api/musical-list`, {
            method: 'POST',
        })
            .then(data => {
                return data.json();
            })
            .then(data => data.data);
    },

    fetchAllSetClamp() {
        return fetch(`/admin-api/set-clamp-list`, {
            method: 'POST',
        })
            .then(data => {
                return data.json();
            })
            .then(data => data.data);
    },

    fetchAllTag() {
        return fetch(`/admin-api/tag-list`, {
            method: 'POST',
        })
            .then(data => {
                return data.json();
            })
            .then(data => data.data);
    },

    fetchAllMode() {
        return fetch(`/admin-api/mode-list`, {
            method: 'POST',
        })
            .then(data => {
                return data.json();
            })
            .then(data => data.data);
    },

    fetchList(filter) {
        return fetch(`/admin-api/music-list`, {
            method: 'POST',
            body: JSON.stringify(filter),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(data => {
                return data.json();
            })
            .then(data => data.data);
    },

    update(data) {
        return fetch(`/admin-api/music-modify`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(data => {
                return data.json();
            })
            .then(data => data.data);
    },
    add(data) {
        return fetch(`/admin-api/music-add`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(data => {
                return data.json();
            })
            .then(data => data.data);
    },
    del(id) {
        return fetch(`/admin-api/music-del`, {
            method: 'POST',
            body: JSON.stringify({ id }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(data => {
                return data.json();
            })
            .then(data => data.data);
    },

    addSetClamp(setClampData) {
        return fetch(`/admin-api/add-clamp`, {
            method: 'POST',
            body: JSON.stringify(setClampData),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(data => {
                return data.json();
            })
            .then(data => data.data);
    },
    updateSetClamp() {
        return fetch(`/admin-api/modify-clamp`, {
            method: 'POST',
            body: JSON.stringify(),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(data => {
                return data.json();
            })
            .then(data => data.data);
    },

    delSetClamp(id) {
        return fetch(`/admin-api/del-clamp`, {
            method: 'POST',
            body: JSON.stringify({ id }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(data => {
                return data.json();
            })
            .then(data => data.data);
    },
};

export default obj;
